<div class="grid md:grid-cols-3 gap-4 my-4" *ngIf="!hiddenKeyMap['quick-filter']">
  <div>
    <div
      
      *ngIf="
        filter['user-id'] &&
        filter['user-id'].length > 0 &&
        !hiddenKeyMap['user-id']
      ">
      <h4>View as</h4>
      <div class="flex flex-wrap fit">
        <ng-container *ngFor="let i of filter['user-id']; let j = index">
            <button
              class="btn m-1 max-w-full h-auto btn-outline times pl-0 text-left"
              (click)="removeFromFilter('user-id', i.id)">
              <app-user-card [userId]="i.id"></app-user-card>
              <!-- <img
                *ngIf="agency.profileImage"
                [src]="agency.profileImage"
                class="rounded-full w-12 h-12 object-cover -ml-4" />
              <div>
                @if (agency.firstName) {
                  <span>{{ agency.firstName ?? '' }}</span>
                } @else if (agency.name) {
                  <span>{{ agency.name ?? '' }}</span>
                } @else {
                  <span>{{ i.id ?? '' }}</span>
                }
              </div> -->
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </ng-container>
      </div>
    </div>
    <div
      
      *ngIf="
        filter['agency-id'] &&
        filter['agency-id'].length > 0 &&
        !hiddenKeyMap['agency-id']
      ">
      <h4>Selected Agencies</h4>
      <div class="flex flex-wrap fit">
        <ng-container *ngFor="let i of filter['agency-id']; let j = index">
          <button
            *ngIf="agencyMap[i.id]; let agency"
            class="btn m-1 max-w-full h-auto btn-outline times"
            (click)="removeFromFilter('agency-id', i.id)">
            <img
              *ngIf="agency.profileImage"
              [src]="agency.profileImage"
              class="rounded-full w-12 h-12 object-cover -ml-4" />
            <div>
              <span>{{ agency.name ?? '' }}</span>
            </div>
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <div
      
      *ngIf="
        filter['agent-id'] &&
        filter['agent-id'].length > 0 &&
        !hiddenKeyMap['agent-id']
      ">
      <h4>Selected Agent</h4>
      <div class="flex flex-wrap fit">
        <ng-container *ngFor="let i of filter['agent-id']; let j = index">
          <button
            *ngIf="agentMap[i.id]; let agent"
            class="btn m-1 max-w-full h-auto btn-outline times"
            (click)="removeFromFilter('agent-id', i.id)">
            <img
              *ngIf="agent.profileImage"
              [src]="agent.profileImage"
              class="rounded-full w-12 h-12 object-cover -ml-4" />
            <div>
              <span
                >{{ agent.firstName ?? '' }} {{ agent.lastName ?? '' }}</span
              >
            </div>
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="md:col-span-2">
    <h4>Agency Filter Type</h4>

    <div class="flex mb-4">
      <div class="border-black rounded-2xl relative" style="border-width: 1px">
        <button
          class="btn btn-sm border-none shadow-none"
          (click)="
            filter['agency-filter-type'] = 'MY_PAYOUTS';
            onFilterChange('agency-filter-type', 'MY_PAYOUTS')
          "
          [class.btn-primary]="
            !filter['agency-filter-type'] ||
            filter['agency-filter-type'] === 'MY_PAYOUTS'
          "
          [class.bg-transparent]="
            filter['agency-filter-type'] &&
            filter['agency-filter-type'] !== 'MY_PAYOUTS'
          ">
          My View
        </button>
        <button
          class="btn btn-sm border-none shadow-none"
          (click)="
            filter['agency-filter-type'] = 'EXECUTIVE_PERSPECTIVE';
            onFilterChange('agency-filter-type', 'EXECUTIVE_PERSPECTIVE')
          "
          [class.btn-primary]="
            !filter['agency-filter-type'] ||
            filter['agency-filter-type'] === 'EXECUTIVE_PERSPECTIVE'
          "
          [class.bg-transparent]="
            filter['agency-filter-type'] &&
            filter['agency-filter-type'] !== 'EXECUTIVE_PERSPECTIVE'
          ">
          Agency View
        </button>
        <button
          class="btn btn-sm border-none shadow-none"
          (click)="
            filter['agency-filter-type'] = 'BUSINESS_WRITTEN';
            onFilterChange('agency-filter-type', 'BUSINESS_WRITTEN')
          "
          [class.btn-primary]="filter['agency-filter-type'] === 'BUSINESS_WRITTEN'"
          [class.bg-transparent]="filter['agency-filter-type'] !== 'BUSINESS_WRITTEN'">
          Agent View
        </button>
      </div>
    </div>
  </div>
</div>
